import { StopOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { arrayCompare, getCoordinates } from '@nextgis/utils';
import area from '@turf/area';
import buffer from '@turf/buffer';
import { Alert, Button, Progress, Space, Spin } from 'antd';
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import { WEBMAP_ID, baselayersPrefix, identifySourceItems } from '../../config';
import connector from '../../services/connector';
import logger from '../../services/logger';
import { featureFromCoords } from '../../utils/featureFromCoords';
import { formatPerfTime } from '../../utils/formatPerfTime';
import { getIdentifyLayers } from '../../utils/getIdentifyLayers';

import { IdentifyControl } from './IdentifyControl';
import { IdentifyResult } from './IdentifyResult';
import { IdentifySettings } from './IdentifySettings';
import { PanelText } from './PanelText';
import { addBaselayers } from './addBaselayers';
import { drawLayerOnMap } from './drawLayerOnMap';
import { PointIdentifyPanel } from './mode-panels/PointPanel/PointPanel';
import { TableIdentifyPanel } from './mode-panels/TablePanel/TablePanel';
import { startIdentify } from './startIdentify';

import type {
  IdentifyLayerItem,
  IdentifyModeProps,
  IdentifyResultProps,
  IdentifySource,
  IdentifySourceType,
  IdentifyType,
} from '../../interfaces';
import type { NgwMap } from '@nextgis/ngw-map';

interface IdentifyPanelProps {
  ngwMap: NgwMap;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function IdentifyTypeControl(
  param: IdentifyModeProps & { identifyType: string },
) {
  switch (param.identifyType) {
    case 'click':
      return <PointIdentifyPanel {...param}></PointIdentifyPanel>;
    case 'table':
      return <TableIdentifyPanel {...param}></TableIdentifyPanel>;
    default:
      return <PanelText>Не выбран способ идентификации</PanelText>;
  }
}

type Action = '+' | '-';

const changeSourceRequestReducer = (state: number, action: Action): number => {
  switch (action) {
    case '+':
      return state + 1;
    case '-':
      return state - 1;
    default:
      return state;
  }
};

export function IdentifyPanel(props: IdentifyPanelProps) {
  const { ngwMap } = props;

  const layerId = 'polygon-table';

  const [coords, setCoords] = useState<number[][] | null>(null);
  const [bufferSize, setBufferSize] = useState<number>(0);

  const identifyAbortControl = useRef<AbortController>();

  const [identifyLayers, setIdentifyLayers] = useState<IdentifyLayerItem[]>([]);

  const [identifyType, setIdentifyType] = useState<IdentifyType>('table');
  const [identifySourceType, setIdentifySourceType] =
    useState<IdentifySourceType>('all');

  const [identifyError, setIdentifyError] = useState<React.ReactNode>();

  const [identifyLoading, setIdentifyLoading] = useState<number>();
  const [identifyResults, setIdentifyResults] = useState<
    IdentifyResultProps[] | null
  >(null);

  const [changeSourceRequestCount, changeSourceRequestDispatch] = useReducer(
    changeSourceRequestReducer,
    0,
  );
  const identifySources = useMemo(() => {
    if (identifySourceType === 'all') {
      return identifySourceItems;
    }
    return identifySourceItems.filter((x) => identifySourceType === x.value);
  }, [identifySourceType]);

  const bufferGeom = useMemo(() => {
    if (bufferSize && coords) {
      return buffer(featureFromCoords(coords), bufferSize, {
        units: 'kilometers',
      });
    }
  }, [coords, bufferSize]);

  const bufferCoords = useMemo(() => {
    if (bufferGeom) {
      return getCoordinates(bufferGeom);
    }
    return coords;
  }, [coords, bufferGeom]);

  const intersectionNotAllowed = useMemo<boolean | string>(() => {
    if (bufferGeom) {
      // 100 000 Ga
      return area(bufferGeom) > 1000000000
        ? 'Площадь не должна превышать 100,000 га.'
        : false;
    }
    return false;
  }, [bufferGeom]);

  const abort = () => {
    if (identifyAbortControl.current) {
      identifyAbortControl.current.abort();
    }
    identifyAbortControl.current = undefined;
  };

  const onGeom = useCallback((coordinates: number[][] | null) => {
    if (coordinates) {
      if (coordinates.length > 2) {
        const polygon = [...coordinates];
        const firstCoord = polygon[0];
        const lastCoords = polygon[polygon.length - 1];
        if (!arrayCompare(firstCoord, lastCoords)) {
          polygon.push(firstCoord);
        }
        coordinates = polygon;
      }
    }
    setCoords(coordinates);
  }, []);

  const drawLayer = useCallback(async () => {
    drawLayerOnMap({
      coords,
      bufferCoords: bufferSize ? bufferCoords : null,
      ngwMap,
      layerId,
      identifyType,
    });
  }, [bufferCoords, bufferSize, coords, identifyType, ngwMap]);

  const removeBaselayers = useCallback(() => {
    const layers = ngwMap.getLayers();
    for (const l of layers) {
      if (l.startsWith(baselayersPrefix)) {
        ngwMap.removeLayer(l);
      }
    }
  }, [ngwMap]);

  const removeOverlays = useCallback(() => {
    ngwMap.removeLayer(layerId);
  }, [ngwMap]);

  const onSourceChange = useCallback(
    async (sources: IdentifySource[], signal: AbortSignal) => {
      if (sources.length) {
        const start = performance.now();

        setIdentifyLayers([]);

        const identifyLayers_: IdentifyLayerItem[] = [];

        try {
          changeSourceRequestDispatch('+');
          const res = await connector.getResourceOrFail(WEBMAP_ID, {
            cache: true,
            signal,
          });
          const webmap = res.webmap;
          if (!webmap) {
            throw new Error('Resource is not a webmap');
          }

          for (const source of sources) {
            const sourceIdentifyLayers = getIdentifyLayers(webmap, source.path);

            for (const identifyLayer of sourceIdentifyLayers) {
              identifyLayers_.push({ source: source.value, ...identifyLayer });
            }
          }
        } catch (er) {
          if ((er as Error).name !== 'AbortError') {
            throw er;
          }
        } finally {
          changeSourceRequestDispatch('-');
        }
        const duration = performance.now() - start;
        if (identifyLayers_.length) {
          setIdentifyLayers(identifyLayers_);
          logger.info(`Identify layers loaded at ${formatPerfTime(duration)}`, {
            duration,
            operationId: 'identify-fetch-layers',
            data: {
              identifyLayersLength: identifyLayers_.length,
              sources: sources.map((x) => x.value),
            },
          });
        } else {
          logger.info(
            `Identify layers loaded error at ${formatPerfTime(duration)}`,
            {
              duration,
              operationId: 'identify-fetch-layers-error',
              data: {
                identifyLayersLength: identifyLayers_.length,
                sources: sources.map((x) => x.value),
              },
            },
          );
        }
      }
    },
    [],
  );

  useEffect(
    function prepareIdentify() {
      const isSourceVisible = identifySources.every(
        (x) => x.showLayers ?? true,
      );
      if (isSourceVisible) {
        addBaselayers({ identifyLayers, ngwMap });
      } else {
        removeBaselayers();
      }
    },
    [identifyLayers, identifySources, ngwMap, removeBaselayers],
  );

  useEffect(() => {
    return () => {
      abort();
      setCoords(null);
    };
  }, []);

  useEffect(() => {
    const changeSourceAbortControl = new AbortController();

    removeOverlays();
    removeBaselayers();
    onSourceChange(identifySources, changeSourceAbortControl.signal);
    return () => {
      changeSourceAbortControl.abort();
    };
  }, [identifySources, onSourceChange, removeBaselayers, removeOverlays]);

  useEffect(() => {
    abort();
    setCoords(null);
  }, [identifyType]);

  useEffect(() => {
    drawLayer();
  }, [drawLayer, identifyLayers]);

  useEffect(() => {
    abort();
    setIdentifyResults(null);
  }, [identifyLayers, coords, bufferSize]);

  const startIdentify_ = async (geom_: number[][]) => {
    abort();
    setIdentifyResults(null);
    identifyAbortControl.current = new AbortController();
    return startIdentify({
      geom: geom_,
      signal: identifyAbortControl.current.signal,
      identifyType,
      identifyLayers,
      identifySources,
      setIdentifyError,
      setIdentifyResults,
      setIdentifyLoading,
    });
  };

  function IdentifyBlock() {
    const resultExist = identifyResults && identifyResults.length;

    if (identifyLoading !== undefined) {
      return (
        <Space direction="horizontal">
          <PanelText>Поиск пересечений</PanelText>
          <Progress steps={10} percent={Math.ceil(identifyLoading)} />
          <Button size="small" icon={<StopOutlined />} onClick={abort}></Button>
        </Space>
      );
    }

    if (!bufferCoords) {
      return null;
    }

    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        {!identifyResults ? (
          <>
            <Button
              block
              disabled={!!intersectionNotAllowed}
              onClick={() => {
                startIdentify_(bufferCoords);
              }}
            >
              Найти пересечения
            </Button>
            {!!intersectionNotAllowed && (
              <Alert
                type="warning"
                message={
                  typeof intersectionNotAllowed === 'string'
                    ? intersectionNotAllowed
                    : 'Невозможно выполнить пересчение'
                }
              />
            )}
          </>
        ) : resultExist ? (
          <IdentifyResult
            coords={bufferCoords}
            bufferSize={bufferSize}
            identifyResults={identifyResults}
            identifyType={identifyType}
          />
        ) : (
          <PanelText>Пересечений не найдено</PanelText>
        )}
        {identifyError && <Alert type="error" message={identifyError} />}
      </Space>
    );
  }

  if (changeSourceRequestCount) {
    return (
      <Space direction="horizontal">
        <PanelText>Загрузка списка ресурсов</PanelText>
        <Spin indicator={antIcon} />
      </Space>
    );
  }

  if (!identifyLayers.length) {
    return (
      <PanelText>Не удалось загрузить список слоёв для идентификации</PanelText>
    );
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <IdentifyControl
        identifyType={identifyType}
        identifyLayers={identifyLayers}
        identifySourceType={identifySourceType}
        setIdentifyType={setIdentifyType}
        setIdentifySourceType={setIdentifySourceType}
      />
      <IdentifyTypeControl
        {...{ identifyType, onGeom, ngwMap }}
      ></IdentifyTypeControl>
      <IdentifySettings {...{ coords, bufferSize, setBufferSize }} />
      <IdentifyBlock />
    </Space>
  );
}
