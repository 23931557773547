import type { IdentifySource, IdentifyType, SelectItem } from './interfaces';

export const baseUrl =
  process.env.NODE_ENV === 'development' ? '' : 'https://demo.nextgis.ru';

export const WEBMAP_ID = 8766;

export const baselayersPrefix = 'baselayers';

export const INTERSECTION_LAYER_ORDER = 1000;
export const INTERSECTED_LAYER_ORDER = 100;

export const MAX_BASELAYER_DISPLAY = 100;

export const identifyTypeItems: SelectItem<IdentifyType>[] = [
  { label: 'Из таблицы', value: 'table' },
  { label: 'В точке', value: 'click' },
];

const identifySourceItems_: IdentifySource[] = [
  {
    label: 'Схема ООПТ',
    value: 'oopt',
    path: ['Схема ООПТ', 'Границы'],
    selectable: true,
  },
  {
    label: 'Лесной план',
    value: 'les',
    path: ['Лесной план'],
    fields: [{ keyname: 'KV', displayName: 'Кварталы' }],
    showLayers: true,
    selectable: true,
  },
  {
    label: 'Схема недропользования',
    value: 'subsurface',
    path: ['Схема недр', 'Участки недр'],
    fields: [{ keyname: 'Наиме', displayName: 'Наименование' }],
    selectable: true,
  },
  {
    label: 'Схема ТТП КМНС',
    value: 'ttp',
    fields: [{ keyname: 'NAME', displayName: 'Наименование' }],
    path: ['ТТП КМНС'],
    selectable: true,
  },
  {
    label: 'Схема охотугодий',
    value: 'hunting',
    path: ['Схема охотугодий'],
    fields: [{ keyname: 'NAME', displayName: 'Наименование' }],
    showLayers: true,
    selectable: true,
  },
  {
    label: 'Красная книга',
    value: 'redbook',
    path: ['Красная книга'],
    selectable: true,
  },
];
export const identifySourceItems = identifySourceItems_.sort((a, b) =>
  a.label.localeCompare(b.label),
);
identifySourceItems.push({
  label: 'Предматериалы КЭО',
  value: 'all',
  path: [],
  showLayers: false,
  selectable: true,
});
