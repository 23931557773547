import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Radio, Row, Select, Space, Tooltip } from 'antd';

import {
  MAX_BASELAYER_DISPLAY,
  identifySourceItems,
  identifyTypeItems,
} from '../../config';
import logger from '../../services/logger';

import type {
  IdentifyLayerItem,
  IdentifySourceType,
  IdentifyType,
} from '../../interfaces';

interface IdentifyControlProps {
  identifyType: IdentifyType;
  identifyLayers: IdentifyLayerItem[];
  identifySourceType: IdentifySourceType;
  setIdentifyType: React.Dispatch<React.SetStateAction<IdentifyType>>;
  setIdentifySourceType: React.Dispatch<
    React.SetStateAction<IdentifySourceType>
  >;
}

export function IdentifyControl({
  identifyType,
  identifyLayers,
  identifySourceType,
  setIdentifySourceType,
  setIdentifyType,
}: IdentifyControlProps) {
  const onIdentifySourceChange = (val: IdentifyType) => {
    setIdentifyType(val);
    logger.info(`Identify source type changed to '${val}'`, {
      operationId: 'identify-source-change',
      data: { identifySourceType: val },
    });
  };

  const onIdentifyInputChange = (val: IdentifySourceType) => {
    setIdentifySourceType(val);
    logger.info(`Identify input type changed to '${val}'`, {
      operationId: 'identify-input-change',
      data: { identifyType: val },
    });
  };

  const identifyLayersLength: Partial<Record<IdentifySourceType, number>> = {};

  return (
    <Row>
      <Radio.Group
        value={identifyType}
        onChange={(e) => onIdentifySourceChange(e.target.value)}
      >
        {identifyTypeItems.map(({ value, label }) => (
          <Radio.Button value={value} key={value}>
            {label}
          </Radio.Button>
        ))}
      </Radio.Group>
      <Select
        value={identifySourceType}
        onChange={onIdentifyInputChange}
        style={{ width: '198px', marginLeft: '5px' }}
      >
        {identifySourceItems
          .filter(({ selectable }) => selectable ?? false)
          .map(({ label, value, showLayers }) => {
            let title = '';
            let visiblePercent = identifyLayersLength[value] ?? 0;
            if (value === identifySourceType) {
              if (identifyLayers.length > MAX_BASELAYER_DISPLAY) {
                visiblePercent = Math.floor(
                  (MAX_BASELAYER_DISPLAY / identifyLayers.length) * 100,
                );
              } else {
                visiblePercent = 100;
              }
              identifyLayersLength[value] = visiblePercent;
            }
            if (visiblePercent) {
              title = `${visiblePercent}%`;
            }
            return (
              <Select.Option key={value} value={value}>
                <Space>
                  {showLayers !== undefined && !showLayers ? (
                    <EyeInvisibleOutlined />
                  ) : (
                    <Tooltip title={title}>
                      <EyeOutlined
                        style={{ opacity: (visiblePercent || 100) / 100 }}
                      />
                    </Tooltip>
                  )}
                  {label}
                </Space>
              </Select.Option>
            );
          })}
      </Select>
    </Row>
  );
}
